.rbc-toolbar button {
  padding: 0.05rem 0.5rem !important;
  font-size: 11 !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
  white-space: nowrap !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}
.overflow-y-scroll {
  overflow-y: scroll !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.min-vh-95 {
  min-height: 95vh !important;
}

.cd-main-container {
  margin-left: -33px !important;
}

.cd-card {
  height: 48.9vh !important;
  width: 100% !important;
  box-sizing: border-box !important;
}

.cd-bottom-card {
  height: 97.5vh !important;
  box-sizing: border-box !important;
}

.test1 {
  height: 43vh !important;
}

.carousel-indicators {
  display: none !important;
}

.carousel-control-prev {
  opacity: 1 !important;
  background-image: none !important;
}

.carousel-control-next {
  opacity: 1 !important;
  background-image: none !important;
}

.text-black {
  color: black !important;
}

.h-10 {
  height: 10% !important;
}

.h-8 {
  height: 8% !important;
}

.rounded-1 {
  border-radius: 10px !important;
}

.rounded-2 {
  border-radius: 20px !important;
}

.h-15 {
  height: 15% !important;
}

.mt-4-5 {
  margin-top: 2.25rem !important;
}

.mb-4-5 {
  margin-bottom: 2.25rem !important;
}

.cd-card-title-font-size {
  font-size: 12px !important;
}

.cd-card-border-red {
  border: 2px solid #f5365c !important;
}

.cd-task-bg-red {
  background-color: #d75a58 !important;
}

.cd-task-bg-dark {
  background-color: #645757 !important;
}

.cd-task-bg-green {
  background-color: #789f8a !important;
}

.b-bottom-subtile {
  border-bottom: 1px solid #d9d9d9;
}

.icon-size-s {
  width: 5px;
  height: 5px;
}

.icon-size-ms {
  width: 25px;
  height: 25px;
}

.icon-size-m {
  width: 40px;
  height: 40px;
}

.card-people-text {
}

.cd-task-card-header {
  background-color: #032f62 !important;
}

.cd-task-text {
  box-sizing: border-box;
  word-break: keep-all !important;
  word-wrap: break-word !important;
  color: black;
  font-weight: 500 !important;
  font-size: 1.4rem !important;
  line-height: 1.4rem !important;
  text-align: left !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.cd-task-status {
  width: 10px;
  height: 10px;
  margin-top: 10px;
  margin-right: 5px;
}

.cd-task-scrollbar {
  overflow-y: scroll !important;
}

.cd-task-scrollbar::-webkit-scrollbar {
  width: 8px;
}
.cd-task-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 4px;
}
