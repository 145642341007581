iframe {
  border: 0;
}

figcaption,
figure,
main {
  display: block;
}

main {
  overflow: hidden;
}

.rbc-header {
  text-overflow: clip !important;
}
